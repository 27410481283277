.summaryCard {
  display: flex;
  border-radius: 0.45vw;
  flex: 0 1 calc(50% - 0.15em);
  flex-direction: column;
}

.container {
  border-radius: 0.45vw;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.25vw;
  margin-left: 0.4vw;
  margin-right: 0.4vw;
  margin-top: 0;
  gap: 0.2vw;
}
