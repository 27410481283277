.container {
  justify-content: flex-end;
}

.innerContainer {
  display: flex;
  padding: 0.3vw;
  border-radius: clamp(0.05em, 0.35vw, 12em);
  border: 1px solid black;
  align-items: center;
}

.innerContainer > input,
.innerContainer > input:focus {
  border-style: none;
  outline: none;
}

.innerContainer > input[type='search']::-webkit-search-decoration:hover,
.innerContainer > input[type='search']::-webkit-search-cancel-button:hover {
  cursor: pointer;
}

.searchBox {
  font-size: 1vw;
  width: 18.4vw;
  margin-left: 0.2em;
  padding: 0.2em;
}

.searchIcon {
  width: 1vw;
  height: 1vw;
  padding-left: 0.3vw;
  padding-right: 0.2vw;
}

.clearIcon {
  width: 1vw;
  height: 1vw;
}

.clearIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5vw;
  height: 1.5vw;
  border-radius: 50%;
}

.clearIconContainer:hover {
  cursor: pointer;
  background-color: #e5e3e9;
}
