.container {
  border-radius: clamp(0.05em, 0.45vw, 12em);
  color: #fff;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: clamp(0.05em, 0.25vw, 1.5em);
  margin-left: clamp(0.05em, 0.4vw, 1.5em);
  margin-right: clamp(0.05em, 0.4vw, 1.5em);
  margin-top: 0;
  gap: 0.2vw;
}

.summaryCard {
  display: flex;
  text-transform: uppercase;
  border-radius: clamp(0.05em, 0.45vw, 12em);
  flex: 0 1 calc(50% - 0.15em);
  flex-direction: column;
  padding-bottom: clamp(0.05em, 0.5vw, 1em);
  padding-top: clamp(0.05em, 1vw, 1em);
}

.servesContainer {
  background-color: var(--secondary);
}

.bullTotalContainer {
  background-color: var(--primary);
}

.title {
  font-size: 0.8vw;
  font-weight: 600;
  letter-spacing: 0.02vw;
}

.totals {
  font-size: 1.5vw;
  font-weight: 600;
  letter-spacing: clamp(0em, 0.5vw, 0.09em);
  line-height: clamp(0.25em, 2.5vw, 1em);
  padding-top: clamp(0.05em, 0.5vw, 1em);
}
