.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.icon {
  width: 1vw;
  height: 1vw;
  color: white;
}
.icon:hover {
  cursor: pointer;
}

.updateMessage {
  color: white;
  margin-left: 0.3vw;
  font-size: 0.7vw;
}
