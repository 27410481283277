.container {
  display: flex;
  height: 40vw;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.message {
  font-size: 1.2vw;
  margin-top: 1vw;
  font-weight: 300;
}
