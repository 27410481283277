.container {
  padding: 0.5em 0;
  width: 100%;
}

.dayIndicator {
  display: flex;
  justify-content: center;
  padding: 0.5em 0;
}
