:root {
  --xs-small-font-size: 0.25em;
  --small-font-size: 0.5em;
  --standard-font-size: 1.5em;
  --large-font-size: 3em;
  --xx-large-font-size: 6em;

  /* --small-font-scaled: clamp(0.25em, 0.75vw + 0.75vh, 4em); */
  /* --small-line-height-scaled: clamp(1em, 0.5vw + 0.5vh, 6em); */

  /* --standard-font-scaled: clamp(0.5em, 1.25vw + 1.25vh, 10em); */
  /* --standard-line-height-scaled: clamp(2em, 1vw + 1vh, 12em); */

  /* Colours */
  /* NOTE: Dropdown Select usings JS Styling and colours may need to updated there too */
  --primary: #140034;
  --primary-light: #43335d;

  --secondary: #5623d8;
  --secondary-light: #e7e2fe;

  --current-day-indicator: #017efa;
}
