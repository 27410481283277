.currentDayContainer {
  width: clamp(4em, 6vw, 14em);
  height: clamp(4em, 6vw, 14em);
  margin: auto;
  margin-bottom: clamp(0.5em, 1vw, 3em);
  background-color: var(--current-day-indicator);
  border-radius: 50%;
  color: #fff;
}

.container {
  width: clamp(4em, 6vw, 14em);
  height: clamp(4em, 6vw, 14em);
  margin: auto;
  margin-bottom: clamp(0.5em, 1vw, 3em);
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.weekDayShortName {
  font-size: clamp(0.25em, 1.05vw, 2em);
  line-height: clamp(0.25em, 1.15vw, 3em);
  padding-top: clamp(0.05em, 0.5vw, 1em);
}

.dayOfMonth {
  font-size: clamp(0.5em, 2.5vw, 10em);
  line-height: clamp(0.5em, 2.5vw, 10em);
}
