.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.daysContainer {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: stretch;
}

.summaryCards {
  display: flex;
  flex-direction: row;
  border-radius: 0.45vw;
  flex: 0 1 calc(50% - 0.15em);
  flex-direction: row;
  padding-bottom: 0.5vw;
  padding-top: 1vw;
}

.bullCard {
  display: flex;
  flex-direction: column;
}
