.card {
  margin-top: clamp(0.05em, 0.25vw, 1.5em);
  margin-bottom: clamp(0.05em, 0.25vw, 1.5em);
  margin-left: clamp(0.05em, 0.4vw, 1.5em);
  margin-right: clamp(0.05em, 0.4vw, 1.5em);
  padding: clamp(0.25em, 0.5vw, 2em);
  border-radius: clamp(0.05em, 0.45vw, 12em);
  display: flex;
  justify-content: space-between;
}

.cardFinalTheme {
  background-color: var(--primary);
  color: #fff;
}

.cardDraftTheme {
  background-color: var(--secondary-light);
  color: #000;
}

.bullInfo {
  display: flex;
  flex-direction: column;
  flex-grow: 3;
}

.bullId {
  display: flex;
  font-size: 1.4vw;
  letter-spacing: clamp(0em, 0.5vw, 0.09em);
  font-weight: 600;
  margin-bottom: 0.1em;
}

.bullComment {
  display: flex;
  font-size: clamp(0.2em, 1vw, 3em);
  line-height: clamp(0.2em, 1vw, 1em);
  letter-spacing: 0;
  font-weight: bold;
  opacity: 0.84;
  text-align: left;
  max-width: fit-content;
}

.bullCommentMultipleLine {
  display: block;
  font-size: clamp(0.2em, 0.95vw, 1.8em);
  line-height: clamp(0.2em, 1.6vw, 1em);
  letter-spacing: 0;
  font-weight: bold;
  opacity: 0.84;
  text-align: left;
  max-width: fit-content;
}

.servesContainer {
  display: flex;
  justify-content: center;
  padding: clamp(0.25em, 0.35vw, 2em);
  align-items: center;
  border-radius: 50%;
  background-color: var(--secondary);
  width: clamp(0.5em, 2vw, 5em);
  height: clamp(0.5em, 2vw, 5em);
}

.serves {
  display: flex;
  font-size: 1.6vw;
  font-weight: bold;
  opacity: 1;
  padding-top: clamp(0.15em, 0.2vw, 1.2em);
  color: #fff;
}

.highlight {
  background-color: #ceff004d;
  color: #f0ffb2;
}
