.MuiPaper-root,
.MuiSnackbarContent-root {
  background-color: #fd8800;
  color: white;
}

.MuiButton-root,
.MuiButton-text {
  color: white;
}
