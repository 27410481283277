.container {
  display: inline-flex;
}

.largeSizeText {
  font-size: clamp(0.5em, 2.5vw, 12em);
  font-weight: 600;
}

.todayButton {
  height: 1.5vw;
  width: 3vw;
  background-color: #fff;
  border-radius: 0.5vw;
  border: 0.15em solid black;
  font-size: 0.7vw;
}

.todayButtonContainer {
  margin-top: 0.2vw;
  display: flex;
  margin-left: 1vw;
  height: 2vw;
  width: 3.5vw;
  justify-content: center;
  align-items: center;
  border-radius: 0.45vw;
}

.todayButtonContainer:hover {
  cursor: pointer;
  background-color: #e5e3e9;
}

.todayButton:hover {
  cursor: pointer;
}

.draftIndicator {
  color: #99300d;
  background-color: #fff6cc;
  border: 0.15rem solid #ffdd33;
  border-radius: 2rem;
  padding: 0.25vw 0.4vw;
  display: flex;
  justify-content: center;
  font-size: 0.5vw;
  font-weight: 900;
  height: 0.5vw;
  margin: auto;
  margin-bottom: 1.3vw;
  text-transform: uppercase;
  margin-left: 1vw;
}
