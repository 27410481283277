.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.noFilterResultsContainer {
  display: flex;
  font-size: 2vw;
  text-align: center;
  width: 100%;
  justify-content: center;
}

.noFilterResultsMessage {
  align-items: center;
  display: flex;
  height: 30vw;
}

.daysContainer {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: stretch;
}

/* Breakpoint for mobile devices */
@media (min-width: 40em) {
  .container {
    flex-direction: row;
  }
}

@media (max-width: 900px) {
  .daysContainer {
    width: 100%;
  }
}
