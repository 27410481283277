/* consider moving the font to the public folder, so it's not added to the static media folder and we have a new font to load with every new version we deploy */
@font-face {
  font-family: 'Overpass';
  src: local('Overpass'), url('./fonts/Overpass/Overpass-VariableFont_wght.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1em;
  font-family: 'Overpass';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
