.container {
  display: flex;
  flex-direction: column;
  padding: 0.5vw;
  border-radius: 0.45vw;
}

.card {
  border-radius: 0.45vw;
}
