.logo {
  height: 3.8vw;
}

.logoContainer {
  padding-left: 2vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
