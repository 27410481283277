.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--primary);
  height: clamp(6em, 5vw, 20em);
}

.rightGroup {
  display: flex;
  flex-direction: row;
}
