.container {
  text-align: center;
  padding: 0.5em 0;
  width: 100%;
  flex-grow: 1;
}

.noCollection {
  font-size: clamp(1.5em, 1.5vw, 4em);
  font-weight: 500;
  padding: 0.5em;
}
