.container {
  margin-top: 0.1vw;
  margin-left: 0.5vw;
}

.button {
  border: none;
  background-color: transparent;
  margin: 0.1vw;
  height: 2.2vw;
  width: 2.2vw;
}

.button:hover:enabled {
  background-color: #e5e3e9;
  border-radius: 50%;
  cursor: pointer;
}

.icon {
  height: 1.5vw;
  width: 1.4vw;
  padding: 0.1vw;
}
