.select {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: clamp(8.5em, 4vw, 20em);
  min-width: 8.5em;
  font-size: clamp(0.5em, 1vw, 8em);
  line-height: clamp(0.25em, 1.5vw, 2em);
}

.icon {
  cursor: pointer;
  padding-left: 0.5em;
  margin-top: 0.15em;
  color: #fff;
}
